import React, { Component } from "react"
import { CardElevation} from '@findep/mf-landings-core'

import aviso_es_ccpa from '../../assets/documents/solicitudPrivacidadCalifornia_ES.pdf'
import aviso_en_ccpa from '../../assets/documents/solicitudPrivacidadCalifornia_EN.pdf'

import aviso_es_ppc from '../../assets/documents/politicaPrivacidadCalifornia_ES.pdf'
import aviso_en_ppc from '../../assets/documents/politicaPrivacidadCalifornia_EN.pdf'

import aviso_es from '../../assets/documents/Aviso_de_Privacidad.pdf'
import aviso_en from '../../assets/documents/Privacy_Notice.pdf'

import terms_es from '../../assets/documents/Terminos_de_uso.pdf'
import terms_en from '../../assets/documents/Terms_of_Use.pdf'

import aviso_es_az from '../../assets/documents/Aviso_es_az.pdf'
import aviso_en_az from '../../assets/documents/Aviso_en_az.pdf'

import aviso_es_tx from '../../assets/documents/Aviso_es_tx.pdf'
import aviso_en_tx from '../../assets/documents/Aviso_en_tx.pdf'

import aviso_es_ca from '../../assets/documents/Aviso_es_ca.pdf'
import aviso_en_ca from '../../assets/documents/Aviso_en_ca.pdf'

import { Grid, Container } from "@material-ui/core"
class TermsConditions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pdf_view:''
           
        }           
    }

   async componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search)
        const option = urlParams.get('option') || 'terms-conditions'
        let  location= window.location.origin === 'http://localhost:8000' ? 'https://afi.tysonbeta.com' : window.location.origin
        let name_pdf=''

        if(option === 'terms-conditions')
        {
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=terms_en        
            }else{
                name_pdf=terms_es                
            }
        }else if(option === 'privacy-notice'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en                
            }else{
                name_pdf=aviso_es                
            }
        }else if(option === 'form-ccpa'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en_ccpa                
            }else{
                name_pdf=aviso_es_ccpa                
            }
        }else if(option === 'privacy-notice-california'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en_ca      
                // name_pdf=aviso_en_ppc                
            }else{
                name_pdf=aviso_es_ca
                // name_pdf=aviso_es_ppc                
            }
        }else if(option === 'privacy-notice-az'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en_az                
            }else{
                name_pdf=aviso_es_az                
            }
        }else if(option === 'privacy-notice-tx'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en_tx                
            }else{
                name_pdf=aviso_es_tx                
            }
        }else if(option === 'privacy-notice-ca'){
            if(this.props.pageContext?.locale === "enUS"){
                name_pdf=aviso_en_ca                
            }else{
                name_pdf=aviso_es_ca
            }
        }        
        location=location+name_pdf        
        let displayPdf=`https://docs.google.com/viewer?url=${location}&embedded=true`
        await this.setState({pdf_view: displayPdf})
        console.log("name_pdf=>",name_pdf)
        console.log("location=>",location)
    }

    render() {        
      const {pdf_view}=this.state
    
        return (
            <Container justify="center" >
                <br/><br/>
                <Grid container spacing={3}>
                   <Grid item xs={12} sm={12}>
                        <CardElevation noPadding fullWidth>                              
                                 {pdf_view && 
                                    <iframe src={pdf_view}  width="100%" height="530px" rel="noopener noreferrer" frameborder="0"></iframe>
                                }   
                                {!pdf_view && 
                                 <div><center><h4>Cargando... <br/> Por favor espere</h4></center></div>
                                }   
                                
                                
                                
                        </CardElevation>                    
                    </Grid>
                </Grid>
            </Container>
        )
    }
}


export default TermsConditions

